import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo" 
import DirectoryCategories from "../../components/content/categories" 
import GridIcons from "../../components/content/gridicons" 

import Menu from "../../components/content/menu"

const BusinessPage = ({ siteTitle, data }) => (
  <StaticQuery
    query={graphql`
      query SolutionPageQuery {
        site {
          siteMetadata {
            title
            url
            blogUrl
            appUrl
            getStartedUrl
            twitterUrl
            linkedInUrl
            feedbackUrl
            description
            homeDescription
            footerDescription
          }
        }
      }
    `}
    render={data => (
      <>
        <Layout>
          <SEO title="Solutions" />
          <div className="jumbotron page-header">
        <div className="container">
          <h1 className="display-32">
          Solutions for your Company 
           
            
          </h1>
          <h2 className="display-32"> 
             Get digital solutions and insights today.{' '}
            <span style={{ color: '#007bff' }}>
              Focus on what matters.
            </span> 
            
          </h2>
          
          {/* <p>
                Your customers need better, smarter and safer intelligence in
                your services and solutions <br/> 
                &amp; your teams need to build more trust.
              </p> */}

              
          <p>
                Your customers expect better, smarter and safer and more intelligence, services and solutions to drive results.
              </p>  
                {/* <p>
 <Menu />   </p> */}
        </div>
      </div>
         {/*    <div className={"page-header"}>
         

          <div className={"call-to-action  "}>
              <div className={"container"}>
                <div className={"call-to-action__content"}>
                  <h2></h2>
                  <p>
                     Sign up to start improving your digital world with {data.site.siteMetadata.title}. 
                    If you’re looking to build or integrate smart, scalable and
                    secure next generation solutions to accelerate success for
                    your business, we can help.
                  </p>

                  <div className="row">
                    <div className="col-md-3 text-white">
                      <h3>Engage Customers</h3>
                    </div>
                    <div className="col-md-3 text-white">
                      <h3>Empower Employees</h3>
                    </div>
                    <div className="col-md-3 text-white">
                      <h3>Optimize Operations</h3>
                    </div>
                    <div className="col-md-3 text-white">
                      <h3>Transform Business</h3>
                    </div>
                  </div>
                </div>
 <div className={"button"}>
              <Link to={data.site.siteMetadata.getStartedUrl} 
                // target={"_blank"}
                >
                  Let's Talk
                </Link>
              </div> 
              </div>
            </div> 
          
          </div>*/}

          <section className="features features-3">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 col-md-offset-1b col-sm-12 text-center">
                    {/* <h3>
                    We ready to help
                    </h3> */}
                    
              <h3>TRANSFORM, INNOVATE &amp; GROW.</h3>

                    <p>
                    <br/><br/>
                    {/* Sign up to start improving your digital world with {data.site.siteMetadata.title}. */}
                    If you’re looking to build or integrate smart, scalable and
                    secure next generation solutions to accelerate success for
                    your business, we can help.
                    </p>
                    <div className="row">
                    <div className="col-md-3 text-white">
                      <h3>Engage Customers</h3>
                    </div>
                    <div className="col-md-3 text-white">
                      <h3>Empower Employees</h3>
                    </div>
                    <div className="col-md-3 text-white">
                      <h3>Optimize Operations</h3>
                    </div>
                    <div className="col-md-3 text-white">
                      <h3>Transform Business</h3>
                    </div>
                  </div>
                  </div>
                </div>
                
                  <div className="row">
                    <div className="col-md-4 text-center feature">
                    
                      <i className="icon mb64 ti-layout-grid4-alt pb-4 pt-4" />
                      <h5>ARTIFICIAL INTELLIGENCE</h5>
                    </div>
                    <div className="col-md-4 text-center feature">
                      {/* <i className="icon-list-thumbnails" /> */}
                      <i className="icon mb64 ti-bar-chart pb-4 pt-4"></i>
                      <h5>DATA SCIENCE</h5>
                    </div>
                    <div className="col-md-4 text-center feature">
                      <i className="icon-hierarchy mb64 pb-4  pt-8" />
                      <h5>DATA PROTECTION</h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 text-center feature"> 
                      <i className="icon mb64 ti-eye   pb-6 pt-6 mb-6"></i>
                      <h5>3D / VR / AR</h5>
                    </div>
                    <div className="col-md-4 text-center feature">
                      <i className="icon-clipboard pb-4 pt-4" />
                      <h5>SPATIAL
</h5>
                    </div>
                    <div className="col-md-4 text-center feature">
                      {/* <i className="icon-file-text" /> */}
                      <i className="icon-graph pb-4 pt-4" />
                      <h5>ANALYTICS</h5>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4 text-center feature">
                      <i className="icon-browser-full pb-4 pt-4" />
                      <h5>WEB</h5>
                    </div>
                    <div className="col-md-4 text-center feature">
                      <i className="icon-cloud pb-4 pt-4" />
                      <h5>CLOUD
</h5>
                    </div>
                    <div className="col-md-4 text-center feature">
                      <i className="icon-iphone pb-4 pt-4" />
                      <h5>MOBILE</h5>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4 text-center feature">
                      <i className="icon-micro-record-streamline" />
                      <h5>INTERNET OF THINGS</h5>
                    </div>
                    <div className="col-md-4 text-center feature">
                      <i className="icon-graph" />
                      <h5>ENTERPRISE
</h5>
                    </div>
                    <div className="col-md-4 text-center feature">
                      <i className="icon-dashboard-speed-streamline" />
                      <h5>HIGH PERFORMANCE</h5>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4 text-center feature">
                      <i className="icon-photo-pictures-streamline" />
                      <h5>AUTOMATION</h5>
                    </div>
                    <div className="col-md-4 text-center feature">
                      <i className="icon-list-thumbnails" />
                      <h5>RISK &amp; COMPLIANCE
</h5>
                    </div>
                    <div className="col-md-4 text-center feature">
                      <i className="icon-lock-locker-streamline" />
                      <h5>CYBER SECURITY</h5>
                    </div>
                  </div>
 
              </div>
              <div className="row">
                <div className="col-md-12 text-center pb-4">
                  {/* <a className="btn btn-primary  btn-sm" href="/why">
                    Learn More about Why TheLeanFounder Exists?
                  </a> */}

                  <div className={"container"}>
                    <div className={"call-to-action__content2"}>
                      {/* <h2>We ready to help</h2> */}
                      <p>
                        {/* Sign up to start improving your digital world with {data.site.siteMetadata.title}. */}
                        {/* If you’re looking to build or integrate smart, scalable
                        and secure next generation solutions to accelerate
                        success for your business, we can help. */}
                      </p>
{/* 
                      <div className="row">
                        <div className="col-md-3">
                          <h3>Engage Customers</h3>
                        </div>
                        <div className="col-md-3">
                          <h3>Empower Employees</h3>
                        </div>
                        <div className="col-md-3">
                          <h3>Optimize Operations</h3>
                        </div>
                        <div className="col-md-3">
                          <h3>Transform Business</h3>
                        </div>
                      </div> */}
                    </div>

                    {/* <div className={"button"}>
              <Link to={data.site.siteMetadata.getStartedUrl} 
                // target={"_blank"}
                >
                  Let's Talk
                </Link>
              </div> */}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 text-center pb-4">
                  <a className="btn btn-outline-primary mb64 " 
                                  href="/lets-talk/?service=solutions"   >
                    Learn More 
                  </a>
                  {/* <a
                                  className="btn btn-outline-primary mb64"
                                >
                                  Learn More
                                </a> */}
                  <div className={"container d-none"}>
                    <div className={"call-to-action__content2"}>
                      {/* <h2>We ready to help</h2> */}
                      <p>
                        {/* Sign up to start improving your digital world with {data.site.siteMetadata.title}. */}
                        {/* If you’re looking to build or integrate smart, scalable
                        and secure next generation solutions to accelerate
                        success for your business, we can help. */}
                      </p>
{/* 
                      <div className="row">
                        <div className="col-md-3">
                          <h3>Engage Customers</h3>
                        </div>
                        <div className="col-md-3">
                          <h3>Empower Employees</h3>
                        </div>
                        <div className="col-md-3">
                          <h3>Optimize Operations</h3>
                        </div>
                        <div className="col-md-3">
                          <h3>Transform Business</h3>
                        </div>
                      </div> */}
                    </div>

                    {/* <div className={"button"}>
              <Link to={data.site.siteMetadata.getStartedUrl} 
                // target={"_blank"}
                >
                  Let's Talk
                </Link>
              </div> */}
                  </div>
                </div>
              </div>
            </section>
            <section className="features features-3">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 col-md-offset-1b col-sm-12 text-center">
                    {/* <h3>
                    We ready to help
                    </h3> */}
                    
              {/* <h3>TRANSFORM, INNOVATE &amp; GROW.</h3> */}
              <h3>TRANSFORMING DATA INTO INSIGHTS</h3>
                    <p>
                    <br/><br/>
                    {/* Sign up to start improving your digital world with {data.site.siteMetadata.title}. */}
                    We can help find strategy and tactical gaps and risks in your vision and roadmap with tailored options to save your business time and money. We enable businesses of all sizes to enjoy the benefits of just a strategic assessment, or as-needed, ongoing expertise.
                    </p>
                    <div className="row">
                    <div className="col-md-4">
                    <h3>   Machine Intelligence</h3> 
Leverage cognitive computing, deep learning, machine learning into software and hardware solutions for the Internet of Things (IoT).

                       </div>
                    <div className="col-md-4">
                    <h3>  Data Science</h3>
Apply analytics, statistics & cognitive computing on big data, real time data, open web data, private data, small data as well.

                    </div>
                    <div className="col-md-4">
                    <h3>   Intelligent Solutions</h3>
We combine data, machine intelligence, engineering & data science to develop next generation technology solutions & deliver digital transformation.
                    </div> 
                  </div>

                  <div className="row">
                    <div className="col-md-4">
                    <h3>
Data-Driven Applications</h3> 
Every organization needs to acquire, manage, analyze & visualize data generated by a growing number of people and machines in our world including from sensors, apps, open data & social media, we can help you build, configure & integrate your data-driven solutions.
                    </div>
                    <div className="col-md-4">
                    <h3>  Intelligent Applications</h3>
Delivering an innovative, game changing user experience and a reliable solution can be a challenge. Let our team help you build smart interfaces, systems engineering, data science and scalable systems on mobile, desktop, cloud, embedded and IoT platforms.
                    
                    </div>
                    <div className="col-md-4">
                   <h3>Technology Solutions</h3> 
Platform solutions including quality, security, testing, monitoring, protection, life cycle management & DevOps automation for private, hybrid and shared clouds such as AWS, Google, Microsoft, along with a variety of beneficial tools and solutions.

                    </div> 
                  </div>
                  </div>
                </div>
                 
              <div className="row">
                <div className="col-md-12 text-center pb-4">
                  <a className="btn btn-primary  btn-sm" href="/why">
                    Learn More about Why We Exist?
                  </a>

                  <div className={"container"}>
                    <div className={"call-to-action__content2"}>
                      {/* <h2>We ready to help</h2> */}
                      <p>
                        {/* Sign up to start improving your digital world with {data.site.siteMetadata.title}. */}
                        {/* If you’re looking to build or integrate smart, scalable
                        and secure next generation solutions to accelerate
                        success for your business, we can help. */}
                      </p>
{/* 
                      <div className="row">
                        <div className="col-md-3">
                          <h3>Engage Customers</h3>
                        </div>
                        <div className="col-md-3">
                          <h3>Empower Employees</h3>
                        </div>
                        <div className="col-md-3">
                          <h3>Optimize Operations</h3>
                        </div>
                        <div className="col-md-3">
                          <h3>Transform Business</h3>
                        </div>
                      </div> */}
                    </div>

                    {/* <div className={"button"}>
              <Link to={data.site.siteMetadata.getStartedUrl} 
                // target={"_blank"}
                >
                  Let's Talk
                </Link>
              </div> */}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 text-center pb-4">
                  {/* <a className="btn btn-outline-primary mb64 " 
                                  href="/lets-talk/?service=solutions"   >
                    Learn More 
                  </a> */}
                  {/* <a
                                  className="btn btn-outline-primary mb64"
                                >
                                  Learn More
                                </a> */}
                  <div className={"container d-none"}>
                    <div className={"call-to-action__content2"}>
                      {/* <h2>We ready to help</h2> */}
                      <p>
                        {/* Sign up to start improving your digital world with {data.site.siteMetadata.title}. */}
                        {/* If you’re looking to build or integrate smart, scalable
                        and secure next generation solutions to accelerate
                        success for your business, we can help. */}
                      </p>
{/* 
                      <div className="row">
                        <div className="col-md-3">
                          <h3>Engage Customers</h3>
                        </div>
                        <div className="col-md-3">
                          <h3>Empower Employees</h3>
                        </div>
                        <div className="col-md-3">
                          <h3>Optimize Operations</h3>
                        </div>
                        <div className="col-md-3">
                          <h3>Transform Business</h3>
                        </div>
                      </div> */}
                    </div>

                    {/* <div className={"button"}>
              <Link to={data.site.siteMetadata.getStartedUrl} 
                // target={"_blank"}
                >
                  Let's Talk
                </Link>
              </div> */}
                  </div>
                </div>
              </div>
              </div>
            </section>
      
         
          <div className={"call-to-action  d-none"}>
            <div className={"container"}>
              <div className={"call-to-action__content"}>
                <h2>Request your Report</h2>
                <p>
                  Request a report for your technology company to evaluate your
                  standards and to ensure your customers trust your standards.
                </p>
              </div>

              <div className={"button"}>
                <Link
                  to="/request-assessment"
                  // target={"_blank"}
                >
                  Request Report
                </Link>
              </div>
            </div>
          </div>

          <section className="features features-3 d-none">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-md-offset-1 col-sm-12 text-center">
                  <h3>
                    Highlight your organizations privacy, policy &amp; cyber
                    security standards.
                  </h3>
                  {/* <h4>
              We help your team save time by pinpointing unclear and potentially
              risky parameters and information where data privacy and personal
              privacy terms are in user agreements and policy documents.
            </h4> */}
                  <p>
                    By proactively monitoring, disclosing, annotating and
                    analyzing your own organization and your vendor's, you can
                    ensure a better future for your customers, team and bottom
                    line.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 text-center feature">
                  <i className="icon-bag" />
                  <h5>Build Trust</h5>
                  <p>
                    Show how your organization is working towards ensuring data
                    privacy that real people, customers &amp; regulators care
                    about. Disclose and showcase personal data privacy
                    parameters related to your app or service's privacy policies
                    as well as compliance 24/7.
                  </p>
                </div>
                <div className="col-md-4 text-center feature">
                  <i className="icon-graph" />
                  <h5>Highlight Standards</h5>
                  <p>
                    Based on the most modern web standards, devices, platforms,
                    operating systems and eco systems from PC, mobile, IoT, AI,
                    XR &amp; more, highlight how your company handles data
                    protection and trust.
                  </p>
                </div>
                <div className="col-md-4 text-center feature">
                  <i className="icon-list-thumbnails" />
                  <h5>Enable Transparency</h5>
                  <p>
                    We provide your end users and customers useful and helpful
                    data and information on your solutions and services to help
                    them instantly evaluate personal data policies and data
                    privacy standards of your team and company.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section className="features features-3 d-none">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-md-offset-1b col-sm-12 text-center">
                  <h3>
                    Does your organization collect personal data or care about
                    customer data?
                  </h3>

                  <p>
                    As the largest, open database of privacy ratings and reports
                    in the world, our business is making high-quality, official
                    privacy data openly available to consumers and customers.
                    Privacy and InfoSec Data that can be trusted, accessed,
                    analysed, monitored and reviewed when and how it’s needed.
                  </p>
                </div>
              </div>

              <GridIcons />
            </div>
            <div className="row">
              <div className="col-md-12 text-center pb-4">
                <Link to="/why" className="btn btn-primary  btn-sm">
                  Learn More about Why TheLeanFounder Exists?
                </Link>
              </div>
            </div>
          </section>

          <section className="features features-3  d-none">
            <div className={"bg-color2"}>
              <div className={"container"}>
                <div className={"integration-guid"}>
                  <div className={"title"}>
                    <h2>
                      We help various tech providers
                      and many more...
                    </h2>
                    <br />
                    <br />
                  </div>
                  <DirectoryCategories />
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </section>

          {/* 
    <div className={"bg-color"}>
      <div className={"container"}>
        <div className={"integration-guid"}>
          <div className={"title"}>
            <h2>How to use TheLeanFounder for Business</h2>
          </div>

          <div className={"content"}>
            <h4>Overview</h4>
            <p>
              As a service provider or business customer, you can use the site
              for free just like end users can but there's additional features
              and benefits for you.
            </p>
            <h4>Step 1</h4>
            <p>
              You can also search for any vendor, product, site, app, service,
              solution or company that's in our database.
            </p>
            

            <h4>Step 2</h4>
            <p>
              You can then view the profile of the company in question and
              relevant information, tools and data at your finger tips.
            </p>
            
            <h4>Step 3</h4>
            <p>
              You can also view more in depth business-only information if you
              or your team members create an account and save the profile in
              your dashboard in your account for future reference and to monitor
              changes to profiles.
            </p>
            
          </div>
        </div>
      </div>
    </div> */}

    
<div className="call-to-action">
            <div className="container">
              <div className="call-to-action__content">
                <h2>Let's talk</h2>
                <p>
                  What are your biggest problems? Do you have
                  questions about possible solutions? We'd like to help. 
                </p>
              </div>
              <div className="button">
                <a 
                                  href="/lets-talk/?service=solutions" >Let's talk</a>
              </div>
            </div>
          </div>
    
{/* 
          <div className={"call-to-action"}>
            <div className={"container"}>
              <div className={"call-to-action__content"}>
                <h2>Sign up for free</h2>
                <p>
                  Sign up to start improving your digital world with{" "}
                  {data.site.siteMetadata.title}.
                </p>
              </div>

              <div className={"button"}>
                <Link
                  to={data.site.siteMetadata.getStartedUrl}
                  // target={"_blank"}
                >
                  Sign Up
                </Link>
              </div>
            </div>
          </div> */}
        </Layout>
      </>
    )}
  />
)


export default BusinessPage
